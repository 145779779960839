import { Amplify } from 'aws-amplify';
import { useEffect, useState } from 'react';

import { AuthSession, fetchAuthSession, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { AuthConfig } from 'src/dto/auth/AuthConfig';
import { EnvVars } from 'src/services/envVars';

export type AuthState = {
  session: AuthSession | null;
  isSignedIn: boolean;
};

export const useAuth = (config: AuthConfig | null) => {
  const [state, setState] = useState<AuthState>({
    session: null,
    isSignedIn: false,
  });

  useEffect(() => {
    async function checkIsUserAlreadySignedIn() {
      try {
        await getUserSession();
      } catch (error) {
        // do nothing
        // signIn callback will fully refresh the page
        if (EnvVars.isDevMode) {
          console.error(error);
        }
      }
    }

    if (config?.options) {
      const { oauth, userPoolId, userPoolWebClientId } = config.options;
      const { scope, redirectSignIn, redirectSignOut, ...rest } = oauth;
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolId,
            userPoolClientId: userPoolWebClientId,
            loginWith: {
              oauth: {
                redirectSignIn: [redirectSignIn],
                redirectSignOut: [redirectSignOut],
                scopes: scope,
                ...rest,
              },
            },
          },
        },
      });
      checkIsUserAlreadySignedIn();
    }
  }, [config?.options]);

  const signIn = () => signInWithRedirect({ provider: { custom: config?.provider ?? '' } });

  const getUserSession = async () => {
    const session = await fetchAuthSession();
    // We don't need to update the state each time, only when jwt token been refreshed
    if (state.session?.tokens?.accessToken?.toString() !== session.tokens?.accessToken.toString())
      setState({ session: session, isSignedIn: true });
    return session;
  };

  return {
    session: state.session,
    isSignedIn: state.isSignedIn,
    api: {
      signIn,
      signOut,
      getUserSession,
    },
  };
};
