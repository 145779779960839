import { ReactNode } from 'react';

type ShowProps<T> = {
  when: T | undefined | null | false;
  fallback?: ReactNode;
  children: ReactNode | ((item: T) => ReactNode);
};

export function Show<T>({ when, fallback = null, children }: ShowProps<T>): JSX.Element | null {
  if (!when) {
    return fallback ? <>{fallback}</> : null;
  }

  if (typeof children === 'function') {
    const renderedChildren = children(when);
    return <>{renderedChildren}</>;
  }

  return <>{children}</>;
}
