import { NoContent, TableBuilder, TableBuilderColumn } from '@visualfabriq/vf-ui-kit';
import { useContext, useMemo } from 'react';

import { PipelineExecution } from 'src/api-new/bifrost';
import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';
import { PipelineStatus } from 'src/domain/pipelines/types';
import { PipelineFilteredDTO } from 'src/dto/pipelines/Pipeline';
import { DayOfWeek, replaceCron } from 'src/utils/awsCron/utils';
import { getTimeZoneName } from 'src/utils/date/getTimeZoneDisplayName';
import { PipelineTableFilters } from '../../types';
import { NoPipelines } from '../NoPipelines';
import { ActionsColumn } from './ActionsColumn';
import { ConfigurationColumn } from './ConfigurationColumn';
import { HistoryColumn } from './HistoryColumn';
import { NameColumn } from './NameColumn';

type PipelinesTableProps = {
  sortColumn: string;
  sortAsc: boolean;
  filters: PipelineTableFilters;
  newPipeline: () => void;
  createUsingTemplate: () => void;
  handleSort: (id: string) => void;
  refresh: () => void;
};

export function PipelinesTable(props: PipelinesTableProps) {
  const { sortColumn, sortAsc, handleSort, createUsingTemplate, newPipeline, filters, refresh } = props;
  const { pipelines, loading } = useContext(PipelinesContext);
  const tableData = useMemo(() => {
    if (!pipelines) return [];
    return (pipelines as unknown as Array<PipelineFilteredDTO>).map<TableData[0]>((pipeline) => {
      const timeZoneShort = getTimeZoneName(pipeline.time_zone ?? 'GMT', 'shortOffset');
      return {
        id: pipeline.id,
        name: pipeline.name ?? '(Empty)',
        status: pipeline.enabled ? 'Enabled' : 'Disabled',
        executions: pipeline.executions,
        description: pipeline.description ?? '',
        schedule: pipeline.schedule
          ? `${replaceCron(pipeline.schedule!, { dow: DayOfWeek.awsToStandard })} (${timeZoneShort})`
          : '(Empty)',
        blocked: pipeline.blocked ?? false,
        _pipeline: pipeline,
      };
    });
  }, [pipelines]);

  if (!loading && !pipelines.length) {
    return filters.length ? (
      <NoPipelines
        filtered={pipelines.length > 0}
        onNewPipeline={newPipeline}
        createUsingTemplate={createUsingTemplate}
      />
    ) : (
      <NoContent />
    );
  }

  return (
    <TableBuilder
      isLoading={loading}
      loadingRows={10}
      overrides={{
        Root: { style: { maxHeight: '90%', overflowY: 'auto' } },
        TableBodyRow: {
          style: ({ $row, $theme }) => {
            return {
              backgroundColor: $row._pipeline.blocked
                ? $theme.colors.backgroundSecondary
                : $theme.colors.backgroundPrimary,
            };
          },
        },
      }}
      data={tableData}
      sortColumn={sortColumn}
      sortOrder={sortAsc ? 'ASC' : 'DESC'}
      onSort={handleSort}
    >
      <TableBuilderColumn id="name" header="Name" sortable>
        {(row: Row) => <NameColumn name={row.name} blocked={row.blocked} />}
      </TableBuilderColumn>
      <TableBuilderColumn id="actions" header="Actions">
        {(row: Row) => <ActionsColumn pipeline={row._pipeline} refresh={refresh} />}
      </TableBuilderColumn>
      <TableBuilderColumn id="enabled" header="Status" sortable>
        {(row: Row) => row.status}
      </TableBuilderColumn>
      <TableBuilderColumn id="history" header="History">
        {(row: Row) => <HistoryColumn pipeline={row._pipeline} />}
      </TableBuilderColumn>
      <TableBuilderColumn id="description" header="Description" sortable>
        {(row: Row) => row.description}
      </TableBuilderColumn>
      <TableBuilderColumn id="schedule" header="Schedule" sortable>
        {(row: Row) => row.schedule}
      </TableBuilderColumn>
      <TableBuilderColumn id="configuration">
        {(row: Row) => <ConfigurationColumn pipeline={row._pipeline} />}
      </TableBuilderColumn>
    </TableBuilder>
  );
}

type Row = {
  id: string;
  name: string;
  status: PipelineStatus;
  executions: PipelineExecution[];
  description: string;
  schedule: string;
  blocked: boolean;
  _pipeline: PipelineFilteredDTO;
};

type TableData = Row[];
