import {
  AddIcon,
  Button,
  DnsIcon,
  FilterListIcon,
  FilterPopover,
  Input,
  RefreshIcon,
  ScheduleIcon,
  SearchIcon,
  Wrapper,
} from '@visualfabriq/vf-ui-kit';

import { Link } from 'react-router-dom';
import { PipelineListItem } from 'src/api-new/bifrost';
import { Show } from 'src/components';
import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { PIPELINE_STATUSES } from 'src/domain/pipelines/constants';
import { pathConstants } from 'src/routes';
import { PipelineTableFilters } from '../../types';

type Props = {
  pipelinesList: PipelineListItem[] | undefined;
  onNewPipeline: () => void;
  onCreateUsingTemplate: () => void;
  onRefresh: () => void;
  filters: PipelineTableFilters;
  onFiltersChange: (filters: PipelineTableFilters) => void;
  onNameQueryChanged: (query: string) => void;
};

function Filter({
  pipelinesList,
  onFiltersChange,
}: {
  pipelinesList: PipelineListItem[];
  onFiltersChange: (filters: PipelineTableFilters) => void;
}) {
  return (
    <Show when={pipelinesList}>
      <FilterPopover
        endEnhancer={null}
        startEnhancer={<FilterListIcon />}
        onApply={(event) => onFiltersChange(event as PipelineTableFilters)}
        filterItems={[
          { key: 'step_name', dimension: 'Step Name', filter_type: 'text', conditions: ['contains'] },
          {
            key: 'id',
            dimension: 'Pipeline',
            filter_type: 'select',
            conditions: ['nin', 'in'],
            value_list: pipelinesList.map((p) => ({ id: p.id, name: p.name })),
          },
          {
            key: 'enabled',
            dimension: 'Pipeline Status',
            filter_type: 'select',
            conditions: ['exact'],
            value_list: PIPELINE_STATUSES.map((status) => ({ id: status, name: status })),
          },
        ]}
        itemIdKey="key"
      />
    </Show>
  );
}

export function PipelinesActions(props: Props) {
  const { pipelinesList, onNewPipeline, onCreateUsingTemplate, onRefresh, onFiltersChange, onNameQueryChanged } = props;
  const userPermission = useUserPermissions();
  const canCreatePipeline = userPermission.has('PipelinesCreate');
  return (
    <Wrapper justifyContent="space-between" gap={200}>
      <Button kind="primary" startEnhancer={AddIcon} disabled={!canCreatePipeline} onClick={onNewPipeline}>
        Create
      </Button>
      <Button startEnhancer={DnsIcon} disabled={!canCreatePipeline} onClick={onCreateUsingTemplate}>
        Module Wizard
      </Button>
      <Link to={`../${pathConstants.DASHBOARD.root}/${pathConstants.DASHBOARD.pipelineSchedule}`}>
        <Button startEnhancer={<ScheduleIcon />} kind="secondary">
          Schedule
        </Button>
      </Link>
      <Input
        startEnhancer={() => <SearchIcon />}
        placeholder="Search by name..."
        onChange={(event) => onNameQueryChanged(event.target.value)}
        clearable
      />
      <Filter pipelinesList={pipelinesList || []} onFiltersChange={onFiltersChange} />
      <Button startEnhancer={RefreshIcon} onClick={onRefresh} kind="secondary">
        Refresh
      </Button>
    </Wrapper>
  );
}
